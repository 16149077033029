import { Injectable } from '@angular/core';
import {
  AuthenticationDetails,
  CognitoIdToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  loginInSuccess$ = new BehaviorSubject<boolean>(false);

  poolData = {
    UserPoolId: environment.cognitoUserPoolId,
    ClientId: environment.cognitoAppClientId,
  };
  userPool: CognitoUserPool;

  constructor() {
    this.userPool = new CognitoUserPool(this.poolData);
  }

  accessToken() {
    var cognitoUser = this.userPool.getCurrentUser();
    let accessToken: string = '';
    if (cognitoUser != null) {
      cognitoUser.getSession((err: any, session: CognitoUserSession) => {
        accessToken = session.getIdToken()?.getJwtToken();
      });
    }

    return accessToken;
  }
  isLoggedIn(): boolean {
    var isAuth = false;

    var cognitoUser = this.userPool.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          alert(err.message || JSON.stringify(err));
        }
        isAuth = session.isValid();
      });
    }
    return isAuth;
  }

  login(email: string, password: string) {
    let authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    let userData = {
      Username: email,
      Pool: this.userPool,
    };
    var cognitoUser = new CognitoUser(userData);
    let loggedIn = false;
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        loggedIn = true;
        this.loginInSuccess$.next(loggedIn);
      },

      onFailure: (err) => {
        alert(err.message || JSON.stringify(err));
        loggedIn = false;
        this.loginInSuccess$.next(loggedIn);
      },
    });
  }

  logOut() {
    var cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.signOut();
    }
  }
}
